var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-lg-5",attrs:{"fluid":""}},[_c('div',{staticClass:"col-12"},[_c('app-modal',{directives:[{name:"show",rawName:"v-show",value:(_vm.isModalVisible),expression:"isModalVisible"}],attrs:{"modal":_vm.modal},on:{"submit-modal":_vm.submitModal}}),_c('v-card',{staticClass:"rounded-card",attrs:{"flat":"","outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.documents.items,"options":_vm.options,"server-items-length":_vm.documents.count,"loading":_vm.loading,"no-data-text":_vm.$t('messages.emptyState', { entity: _vm.$tc('models.document.entity') }),"no-results-text":_vm.$t('messages.emptyState', { entity: _vm.$tc('models.document.entity') }),"flat":""},on:{"update:options":[function($event){_vm.options=$event},_vm.updateOptions]},scopedSlots:_vm._u([{key:"item.attachment",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"row"},[_vm._v(_vm._s(item.attachment.filename))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.downloadDocument({filename: item.attachment.filename, url: _vm.getFileUrl(item.attachment.url)})}}},[_c('v-icon',[_vm._v("mdi-download")])],1),(_vm.isAdmin)?_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.deleteDocument(item)}}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1):_vm._e()]}}],null,true)})],1)],1),(_vm.isAdmin)?_c('v-fab-transition',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-5",attrs:{"color":"primary","fab":"","bottom":"","right":"","fixed":""},on:{"click":_vm.newDocument}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,false,4042551368)},[_c('span',[_vm._v(_vm._s(_vm.$t('layout.accountNavMenu.newDocument')))])])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }