<template>
  <v-container fluid class="px-lg-5">
    <div class="col-12">
      <app-modal
        v-show="isModalVisible"
        :modal="modal"
        @submit-modal="submitModal"
      />
      <v-card class="rounded-card" flat outlined>
        <v-data-table
          :headers="headers"
          :items="documents.items"
          :options.sync="options"
          @update:options="updateOptions"
          :server-items-length="documents.count"
          :loading="loading"
          :no-data-text="$t('messages.emptyState', { entity: $tc('models.document.entity') })"
          :no-results-text="$t('messages.emptyState', { entity: $tc('models.document.entity') })"
          flat
        >
          <template v-slot:[`item.attachment`]="{ item }">
            <span class="row">{{ item.attachment.filename }}</span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon small
              @click="downloadDocument({filename: item.attachment.filename, url: getFileUrl(item.attachment.url)})">
              <v-icon>mdi-download</v-icon>
            </v-btn>
            <v-btn v-if="isAdmin" icon small @click="deleteDocument(item)">
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </div>
    <v-fab-transition v-if="isAdmin">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" fab bottom right fixed class="mr-5" v-on="on" v-bind="attrs" @click="newDocument">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('layout.accountNavMenu.newDocument') }}</span>
      </v-tooltip>
    </v-fab-transition>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
  data() {
    return {
      loading: false,
      isModalVisible: false,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['id'],
        sortDesc: [false],
        mustSort: ['true'],
        multiSort: false
      },
      modal: {
        show: false,
        id: '',
        behaviour: '',
        resetForm: false,
        title: '',
        submitButton: {
          label: this.$t('actions.save')
        },
        fields: {
          title: {
            type: 'text',
            key: 'title',
            value: '',
            outlined: true,
            dense: true,
            label: this.$t('models.document.attributes.title'),
            rules: [{name: 'required'}],
            classes: 'col-12 py-0',
            serverErrors: []
          },
          attachment: {
            type: 'file',
            key: 'attachment',
            showImage: true,
            value: null,
            outlined: true,
            dense: true,
            clearable: true,
            showSize: true,
            accept: 'image/*, .pdf',
            icon: 'mdi-camera',
            label: this.$t('models.document.attributes.file'),
            rules: [{name: 'required'}],
            classes: 'col-12 py-0',
            serverErrors: []
          }
        }
      },
      headers: [{
        text: '',
        value: 'id',
        align: ' d-none'
      },
      {
        text: this.$t('models.document.attributes.title'),
        value: 'title'
      },
      {
        text: this.$t('models.document.attributes.filename'),
        value: 'attachment.filename'
      },
      {
        text: '',
        value: 'actions',
        sortable: false,
        width: '120px'
      }]
    };
  },
  computed: mapGetters(['documents', 'currentUser', 'isAdmin']),
  methods: {
    ...mapActions(['getDocuments', 'createDocument', 'updateDocument', 'destroyDocument', 'downloadDocument']),
    updateOptions(options) {
      this.loading = true;
      this.getDocuments({
        ...options,
        filterBy: {
          userId: this.$route.params.uuid === 'me' ? this.currentUser.id : this.$route.params.uuid
        }
      }).then(() => {
        this.loading = false;
      });
    },
    submitModal(data) {
      const formData = new FormData();
      for (const key in data) {
        if (data[key]) {
          if (key === 'attachment') {
            if (data[key]) {
              formData.append(key, data[key][0]);
            }
          } else {
            formData.append(key, data[key]);
          }
        }
      }
      formData.append('user_id', this.$route.params.uuid === 'me' ? this.currentUser.id : this.$route.params.uuid);

      this.createDocument(formData).then((response) => {
        if (this.successCode(response.status)) {
          this.modal.show = false;
          this.updateOptions(this.options);
        } else {
          this.displayInlineServerErrors(this.modal.fields, response.errors);
        }
      });
    },
    newDocument() {
      this.modal.resetForm = true;
      this.clearInlineServerErrors(this.modal.fields);
      this.modal.title = this.$t('views.documents.newDocument');
      this.modal.fields.title.value = '';
      this.modal.fields.attachment.value = null;
      this.modal.behaviour = 'createDocument';
      this.modal.show = true;
      this.$nextTick(() => {
        this.modal.resetForm = false;
      });
    },
    deleteDocument(document) {
      this.$confirm(
        this.$t('messages.confirmation.delete', {entity: document.title}),
        {
          buttonTrueText: this.$t('actions.confirm'),
          buttonFalseText: this.$t('actions.cancel')
        }
      ).then((confirmed) => {
        if (confirmed) {
          this.destroyDocument(document.id).then((status) => {
            if (this.successCode(status)) {
              this.updateOptions(this.options);
            }
          });
        }
      });
    }
  }
};
</script>
